.text-header {
  font-family: "Bell MT";
}
.text-description {
  font-family: "Avenir-Next";
}

#landing-page g:hover>path {
  /* stroke: #afa019!important; */
  transform: scale(1.2);
}

#landing-page g.selected path {
  stroke: #bfb5a6!important;
  /* transform: scale(1.2); */
}